import { Form, Input, Modal, Select, Typography } from "antd";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";

const ViewUserModal = ({ setModalOpen, modalOpen, data }) => {
  const [form] = Form.useForm();

  const user = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoading] = useState(false);
  const [availableRoles, setAvailableRoles] = useState([]);

  useEffect(() => {
    axios
      .get(`${import.meta.env.VITE_APP_BASE_API_URL}/roles/get-roles`, {
        headers: {
          Authorization: `Bearer ${user?.data?.accessToken}`,
        },
      })
      .then((res) => setAvailableRoles(res.data.roles));
  }, []);

  const onFinish = async (values) => {
    console.log(values, "val");
    const patchData = {
      role: values.role,
      active: values.active ? 1 : 0,
      password: values.newPassword,
      email: values.email,
    };
    setLoading(true);
    axios
      .patch(
        `${import.meta.env.VITE_APP_BASE_API_URL}/users?id=${data.id}`,
        patchData,
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        setModalOpen(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setModalOpen(false);
  };
  return (
    <Modal
      okText={"Save"}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onFinish(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      okButtonProps={{ disabled: loading }}
      onCancel={handleCancel}
      open={modalOpen}
    >
      <Form
        form={form}
        name="edit-user"
        initialValues={{
          role: data?.roleDetails?.id,
          active: data?.active,
          email: data?.email,
        }}
        layout="vertical"
      >
        <div className="view-super-body">
          <Typography.Paragraph level={3}>
            Name:{" "}
            {data?.userProfile?.firstName + " " + data?.userProfile?.lastName}
          </Typography.Paragraph>
          {[1, 2, 12].includes(user.roles.id) ? (
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  type: "email",
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          ) : (
            <Typography.Paragraph level={3}>
              Email: {data?.email}
            </Typography.Paragraph>
          )}
          <Typography.Paragraph level={3}>
            Nationality: {data?.userProfile?.nationality}
          </Typography.Paragraph>

          {[1, 2, 12].includes(user.roles.id) && (
            <Form.Item label="Role" name="role">
              <Select
                style={{ width: "100%" }}
                options={availableRoles.map((role) => ({
                  label: role.name,
                  value: role.id,
                }))}
              />
            </Form.Item>
          )}
          <Form.Item label="Account Status" name="active">
            <Select
              style={{ width: "100%" }}
              options={[
                { label: "Active", value: true },
                { label: "Inactive", value: false },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="newPassword"
            label="New Password (leave it empty to keep it unchanged)"
          >
            <Input.Password placeholder="input password" />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default ViewUserModal;
