import { Button, Form, Input, InputNumber, Modal } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import UploadItemIcon from "../../components/UploadIcon";

const EditHousekeepingItem = ({ item, refetch }) => {
  const [editItemModal, setEditItemModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const user = JSON.parse(localStorage.getItem("user"));
  const [image, setimage] = useState("");

  useEffect(() => {
    if (item?.icon) {
      setimage(item.icon);
    }
  }, [item]);


  const onFinish = async (values) => {
    setLoading(true);
    console.log("Success:", values);


    // Prepare the payload, including images only if they exist
    const payload = {
      name: {
        english: values.itemName,
        russian: values.itemNameRU,
        arabic: values.itemNameAR,
      },
      description: {
        english: "desc", // Update as per your requirement
        russian: "desc", // Update as per your requirement
        arabic: "desc",  // Update as per your requirement
      },
      price: values.price || 0, // Include price if provided
      timer: values.timer,
      icon: image,

    };



    try {
      const response = await axios.patch(
        `${import.meta.env.VITE_APP_BASE_API_URL}/items?id=${item.id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      );
      console.log(response);
      refetch();
      setEditItemModal(false);
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.message || "An error occurred",
      });
    } finally {
      setLoading(false);
    }
  };


  const handleCancel = () => {
    form.resetFields();
    setEditItemModal(false);
  };

  return (
    <>
      <Button
        style={{ marginRight: 16 }}
        onClick={() => setEditItemModal(true)}
      >
        View
      </Button>
      <Modal
        confirmLoading={loading}
        okText={"Edit Item"}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              onFinish(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
        onCancel={handleCancel}
        open={editItemModal}
        centered
      >
        <Form
          form={form}
          initialValues={{
            price: item?.price,
            timer: item?.timer,
            itemName: item?.name?.english,
            itemNameRU: item?.name?.russian,
            itemNameAR: item?.name?.arabic,
          }}
          layout="vertical"
          name="edit-housekeeping-item-modal"
        >
          <Form.Item name="icon">
            <UploadItemIcon
              value={image ? image : ""} // Ensure this gets the first image link or an empty string
              onChange={(imageURL) => {
                console.log("Uploaded Image URL:", imageURL);
                setimage(imageURL); // Update image list
              }}
            />
          </Form.Item>

          <Form.Item
            name="itemName"
            label="Item Name"
            rules={[
              {
                required: true,
                message: "Please input the item name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="itemNameRU"
            label="Item Name in Russian"
            rules={[
              {
                required: true,
                message: "Please input the item name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="itemNameAR"
            label="Item Name in Arabic"
            rules={[
              {
                required: true,
                message: "Please input the item name!",
              },
            ]}
          >
            <Input dir="rtl" />
          </Form.Item>
          <Form.Item
            name="timer"
            label="Time Estimated (minutes)"
            rules={[
              {
                type: "number",
                required: true,
                message: "Please input the Time Estimate!",
              },
            ]}
          >
            <InputNumber style={{ width: "100%" }} controls={false} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default EditHousekeepingItem;
