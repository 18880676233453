import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Upload,
} from "antd";
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useState, useEffect, useCallback } from "react";
import UploadMultipleImagesHotel from "../../components/UploadMultipleImagesHotel";
import ReactQuill from "react-quill";

dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;

const ViewOfferModal = ({ setModalOpen, modalOpen, data, refetch }) => {
  const [form] = Form.useForm();
  const user = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoading] = useState(false);
  
  // State for image list
  const [imageList, setImageList] = useState([]);

  // States for description fields
  const [desc, setDesc] = useState("");
  const [descAR, setDescAR] = useState("");
  const [descRU, setDescRU] = useState("");

  const handleImagesChange = useCallback((newImages) => {
    setImageList(newImages);
  }, []);

  useEffect(() => {
    if (modalOpen) {
      console.log("Updated imageList in ViewOfferModal:", imageList);
      setDesc(data?.description?.english || "");
      setDescAR(data?.description?.arabic || "");
      setDescRU(data?.description?.russian || "");
      setImageList(data?.images || []); // Initialize image list from the data
    }
  }, [data, modalOpen]);

  // Update form fields when state changes
  useEffect(() => {
    form.setFieldsValue({
      description: desc,
      descriptionAR: descAR,
      descriptionRU: descRU,
    });
  }, [desc, descAR, descRU, form]);

  // Fetch offer data
  useEffect(() => {
    const fetchOfferData = async () => {
      if (data?.id) {
        try {
          const response = await axios.get(
            `${import.meta.env.VITE_APP_BASE_API_URL}/offers?id=${data.id}`,
            {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            }
          );
          const offer = response.data.offer;
          form.setFieldsValue({
            title: offer.title.english,
            titleRU: offer.title.russian,
            titleAR: offer.title.arabic,
            information: offer.information.english,
            informationRU: offer.information.russian,
            informationAR: offer.information.arabic,
            terms: offer.termsAndConditions.english,
            termsRU: offer.termsAndConditions.russian,
            termsAR: offer.termsAndConditions.arabic,
            price: offer.price,
            percentage: offer.percentage,
            validFrom: dayjs(offer.validFrom),
            validUntil: dayjs(offer.validUntil),
          });
          setImageList(offer.images || []);
        } catch (error) {
          console.error("Error fetching offer:", error);
        }
      }
    };

    fetchOfferData();
  }, [data, form, user.token]);

  const handleCancel = () => {
    setModalOpen(false);
  };

  const onFinish = async (values) => {
    const patchData = {
      hotel: data?.hotel?.id || null,
      title: {
        english: values.title,
        russian: values.titleRU,
        arabic: values.titleAR,
      },
      information: {
        english: values.information,
        russian: values.informationRU,
        arabic: values.informationAR,
      },
      description: {
        english: values.description,
        russian: values.descriptionRU,
        arabic: values.descriptionAR,
      },
      termsAndConditions: {
        english: values.terms,
        russian: values.termsRU,
        arabic: values.termsAR,
      },
      price: values.price,
      percentage: values.percentage,
      validFrom: dayjs(values.validFrom).format("YYYY-MM-DD"),
      validUntil: dayjs(values.validUntil).format("YYYY-MM-DD"),
      images: imageList.map((img) => ({
        link: img.link,
        fileName: img.fileName,
      })),
    };

    setLoading(true);
    try {
      const response = await axios.patch(
        `${import.meta.env.VITE_APP_BASE_API_URL}/offers?id=${data?.id}`,
        patchData,
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      );
      console.log("Success:", response);
      refetch();
      setModalOpen(false);
    } catch (error) {
      console.error("Error updating offer:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      okText={"Save"}
      onOk={() => {
        form
          .validateFields()
          .then(onFinish)
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      okButtonProps={{ disabled: loading }}
      onCancel={handleCancel}
      open={modalOpen}
    >
      <Form
        form={form}
        name="edit-offer"
        layout="vertical"
        initialValues={{
          title: data?.title?.english,
          titleRU: data?.title?.russian,
          titleAR: data?.title?.arabic,
          information: data?.information?.english,
          informationAR: data?.information?.arabic,
          informationRU: data?.information?.russian,
          terms: data?.termsAndConditions?.english,
          termsAR: data?.termsAndConditions?.arabic,
          termsRU: data?.termsAndConditions?.russian,
          price: data?.price,
          percentage: data?.percentage,
          validFrom: dayjs(data?.validFrom),
          validUntil: dayjs(data?.validUntil),
          images: imageList,
        }}
      >
        <Form.Item name={"images"}>
          <UploadMultipleImagesHotel data={imageList} onChange={handleImagesChange} />
        </Form.Item>

        <Form.Item
          label="Title"
          name="title"
          rules={[{ required: true, message: "Please input a title!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Title in Russian"
          name="titleRU"
          rules={[{ required: true, message: "Please input a title!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Title in Arabic"
          name="titleAR"
          rules={[{ required: true, message: "Please input a title!" }]}
        >
          <Input dir="rtl" />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: "Please input a description!" }]}
        >
          <ReactQuill theme="snow" value={desc} onChange={setDesc} />
        </Form.Item>

        <Form.Item
          name="descriptionRU"
          label="Description in Russian"
          rules={[{ required: true, message: "Please input a description!" }]}
        >
          <ReactQuill theme="snow" value={descRU} onChange={setDescRU} />
        </Form.Item>

        <Form.Item
          name="descriptionAR"
          label="Description in Arabic"
          rules={[{ required: true, message: "Please input a description!" }]}
        >
          <ReactQuill theme="snow" value={descAR} onChange={setDescAR} />
        </Form.Item>

        <Form.Item
          name="information"
          label="Information"
        >
          <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
        </Form.Item>

        <Form.Item
          name="informationRU"
          label="Information in Russian"
        >
          <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
        </Form.Item>

        <Form.Item
          name="informationAR"
          label="Information in Arabic"
        >
          <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
        </Form.Item>

        <Form.Item
          name="terms"
          label="Terms and Conditions"
        >
          <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
        </Form.Item>

        <Form.Item
          name="termsRU"
          label="Terms in Russian"
        >
          <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
        </Form.Item>

        <Form.Item
          name="termsAR"
          label="Terms in Arabic"
        >
          <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
        </Form.Item>

        <Form.Item label="Price" name="price">
          <InputNumber />
        </Form.Item>

        <Form.Item label="Discount Percentage" name="percentage">
          <InputNumber />
        </Form.Item>

        <Form.Item label="Validity Period">
          <Form.Item
            name="validFrom"
            noStyle
            rules={[{ required: true, message: "Please select a valid date!" }]}
          >
            <DatePicker format={"YYYY-MM-DD"} />
          </Form.Item>
          <span style={{ margin: "0 10px" }}>to</span>
          <Form.Item
            name="validUntil"
            noStyle
            rules={[{ required: true, message: "Please select a valid date!" }]}
          >
            <DatePicker format={"YYYY-MM-DD"} />
          </Form.Item>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ViewOfferModal;
