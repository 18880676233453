import { Button, Form, Input, Modal } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import UploadItemIcon from "../components/UploadIcon";

const EditServiceCategory = ({ categ, refetch }) => {
  const [editCategoryModal, setEditCategoryModal] = useState(false);
  const [form] = Form.useForm();
  const user = JSON.parse(localStorage.getItem("user"));
  const [imageList, setImageList] = useState([]); // Initialize as empty array
  const [image, setimage] = useState("");

  useEffect(() => {
    console.log("categ?.images  :", categ)
    if (categ?.images) {
      setImageList(categ.images);
    }
  }, [categ]);


  useEffect(() => {
    if (imageList.length > 0) {
      setimage(imageList[0].link); // Set `image` to the first item in `imageList`
    }
  }, [imageList]); // Logs whenever `imageList` updates


  const onFinish = async (values) => {

    const requestData = {
      active: true,
      name: {
        english: values.categName,
        russian: values.categNameRU,
        arabic: values.categNameAR,
      },
      description: {
        english: "desc",
        russian: "desc",
        arabic: "desc",
      },
      images: imageList.map((img) => ({
        fileName: img.fileName || img.link.split('/').pop(), // Make sure to correctly get fileName
        link: img.link,
      })),
    };

    console.log("Request Data:", requestData);

    try {
      const response = await axios.patch(
        `${import.meta.env.VITE_APP_BASE_API_URL}/serviceCategories?id=${categ.id}`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      );
      console.log("Response Data:", response.data);
      refetch();
      form.resetFields(); // Reset fields after successful update
      setEditCategoryModal(false);
    } catch (error) {
      console.error("API Error:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.message || "Something went wrong!",
      });
    }
  };


  const handleCancel = () => {
    form.resetFields();
    setEditCategoryModal(false);
  };

  return (
    <>
      <Button
        style={{ marginLeft: 16 }}
        onClick={() => setEditCategoryModal(true)}
      >
        Edit
      </Button>
      <Modal
        okText={"Edit Category"}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              onFinish(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
        onCancel={handleCancel}
        open={editCategoryModal}
        centered
      >
        <Form
          form={form}
          initialValues={{
            categName: categ?.name?.english,
            categNameAR: categ?.name?.arabic,
            categNameRU: categ?.name?.russian,
            icon: image,
          }}
          layout="vertical"
          name="edit-excursion-modal"
        >
          <Form.Item name="icon">
            <UploadItemIcon
              value={image ? image : ""} // Ensure this gets the first image link or an empty string
              onChange={(imageURL) => {
                console.log("Uploaded Image URL:", imageURL);
                const newImage = {
                  fileName: imageURL.split('/').pop(),
                  link: imageURL,
                };
                setImageList([newImage, ...imageList]); // Update image list
              }}
            />
          </Form.Item>



          <Form.Item
            name="categName"
            label="Category Name"
            rules={[{ required: true, message: "Please input the Category name!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="categNameRU"
            label="Category Name in Russian"
            rules={[{ required: true, message: "Please input the Category name!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="categNameAR"
            label="Category Name in Arabic"
            rules={[{ required: true, message: "Please input the Category name!" }]}
          >
            <Input dir="rtl" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default EditServiceCategory;
