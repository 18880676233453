import { Form, Input, Modal, TimePicker } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import Swal from "sweetalert2";
import UploadItemIcon from "../../components/UploadIcon";
import { useState, useEffect } from "react";
import ReactQuill from "react-quill";

dayjs.extend(customParseFormat);

const EditOutletModal = ({
  setEditItemModal,
  editItemModal,
  item,
  refetch,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [extraFieldsParsed, setExtraFieldsParsed] = useState(null);
  const [desc, setDesc] = useState("");
  const [descAR, setDescAR] = useState("");
  const [descRU, setDescRU] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));

  // Parse extraFields if it's a string
 
  useEffect(() => {
    setDesc(item?.description?.english );
    setDescAR(item?.description?.arabic );
    setDescRU(item?.description?.russian )
    if (typeof item?.extraFields === "string") {
      try {
        const parsedExtraFields = JSON.parse(item.extraFields);
        setExtraFieldsParsed(parsedExtraFields);
      } catch (e) {
        console.error("Error parsing extraFields:", e);
      }
    } else {
      setExtraFieldsParsed(item.extraFields);
    }
  }, [item]);

  const onFinish = async (values) => {
    setLoading(true);

    let images;
    if (values.icon) {
      images = [{ link: values.icon }];
    }

    const payload = {
      name: item.name,
      description: item.description,
      extraFields: {
        ...extraFieldsParsed, // Preserve unchanged extraFields
        phone: values.phone || extraFieldsParsed?.phone,
        description: {
          english: values.description || extraFieldsParsed?.description?.english,
          russian: values.descriptionRU || extraFieldsParsed?.description?.russian,
          arabic: values.descriptionAR || extraFieldsParsed?.description?.arabic,
        },
        outletType: {
          english: values.outletType || extraFieldsParsed?.outletType?.english,
          russian: values.outletTypeRU || extraFieldsParsed?.outletType?.russian,
          arabic: values.outletTypeAR || extraFieldsParsed?.outletType?.arabic,
        },
        openingHours: {
          from: values.time
            ? values.time[0].format("HH:mm")
            : extraFieldsParsed?.openingHours?.from,
          to: values.time
            ? values.time[1].format("HH:mm")
            : extraFieldsParsed?.openingHours?.to,
          description: {
            english: values.openingHoursDesc || extraFieldsParsed?.openingHours?.description?.english,
            arabic: values.openingHoursDescAR || extraFieldsParsed?.openingHours?.description?.arabic,
            russian: values.openingHoursDescRU || extraFieldsParsed?.openingHours?.description?.russian,
          },
        },
        location: {
          // lat: values.locationLat || extraFieldsParsed?.location?.lat,
          // lng: values.locationLng || extraFieldsParsed?.location?.lng,
          name: {
            english: values.locationName || extraFieldsParsed?.location?.name?.english,
            russian: values.locationNameRU || extraFieldsParsed?.location?.name?.russian,
            arabic: values.locationNameAR || extraFieldsParsed?.location?.name?.arabic,
          },
        },
      },
    };

    if (images) {
      payload.images = images;
    }

    try {
      const response = await axios.patch(
        `${import.meta.env.VITE_APP_BASE_API_URL}/items?id=${item.id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      );
      refetch();
      setEditItemModal(false);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.message || "Something went wrong!",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setEditItemModal(false);
  };

  if (!extraFieldsParsed) return null; // Ensure the extraFields are parsed before rendering the form

  return (
    <Modal
      confirmLoading={loading}
      okText={"Edit item"}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onFinish(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      onCancel={handleCancel}
      open={editItemModal}
      centered
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          icon: item?.icon,
          phone: extraFieldsParsed?.phone,
          outletType: extraFieldsParsed?.outletType?.english,
          outletTypeRU: extraFieldsParsed?.outletType?.russian,
          outletTypeAR: extraFieldsParsed?.outletType?.arabic,
          description: extraFieldsParsed?.description?.english,
          descriptionRU: extraFieldsParsed?.description?.russian,
          descriptionAR: extraFieldsParsed?.description?.arabic,
          time: [
            dayjs(extraFieldsParsed?.openingHours?.from, "HH:mm"),
            dayjs(extraFieldsParsed?.openingHours?.to, "HH:mm"),
          ],
          openingHoursDesc: extraFieldsParsed?.openingHours?.description?.english,
          openingHoursDescRU: extraFieldsParsed?.openingHours?.description?.russian,
          openingHoursDescAR: extraFieldsParsed?.openingHours?.description?.arabic,
          locationName: extraFieldsParsed?.location?.name?.english,
          locationNameRU: extraFieldsParsed?.location?.name?.russian,
          locationNameAR: extraFieldsParsed?.location?.name?.arabic,
          locationLat: extraFieldsParsed?.location?.lat,
          locationLng: extraFieldsParsed?.location?.lng,
        }}
        name="editOutletForm"
      >
        <Form.Item name="icon">
          <UploadItemIcon />
        </Form.Item>
        <Form.Item name="outletType" label="Outlet Type">
          <Input />
        </Form.Item>
        <Form.Item name="outletTypeRU" label="Outlet Type in Russian">
          <Input />
        </Form.Item>
        <Form.Item name="outletTypeAR" label="Outlet Type in Arabic">
          <Input dir="rtl" />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Restaurant Phone"
          rules={[
            {
              required: true,
              message: "Please input the restaurant phone number!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Description" name="description">
          {/* <Input.TextArea /> */}
          <ReactQuill theme="snow" value={desc} onChange={setDesc} key={`desc-${editItemModal}`} />
        </Form.Item>
        <Form.Item label="Description in Russian" name="descriptionRU">
          {/* <Input.TextArea /> */}
          <ReactQuill theme="snow"value={desc} onChange={setDesc} key={`desc-${editItemModal}`} />
        </Form.Item>
        <Form.Item label="Description in Arabic" name="descriptionAR">
          {/* <Input.TextArea dir="rtl" /> */}
          <ReactQuill theme="snow" value={desc} onChange={setDesc} key={`desc-${editItemModal}`}/>
        </Form.Item>
        <Form.Item
          name="time"
          label="Opening Hours (FROM : TO)"
          rules={[
            {
              required: true,
              message: "Please input the opening hours!",
            },
          ]}
        >
          <TimePicker.RangePicker
            allowClear={false}
            format={"HH:mm"}
            minuteStep={15}
          />
        </Form.Item>
        <Form.Item label="Opening Hours Description" name="openingHoursDesc">
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label="Opening Hours Description in Russian"
          name="openingHoursDescRU"
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label="Opening Hours Description in Arabic"
          name="openingHoursDescAR"
        >
          <Input.TextArea dir="rtl" />
        </Form.Item>
        <Form.Item name="locationName" label="Location Name">
          <Input />
        </Form.Item>
        <Form.Item name="locationNameRU" label="Location Name in Russian">
          <Input />
        </Form.Item>
        <Form.Item name="locationNameAR" label="Location Name in Arabic">
          <Input dir="rtl" />
        </Form.Item>
        {/* <Form.Item name="locationLat" label="Latitude">
          <Input />
        </Form.Item>
        <Form.Item name="locationLng" label="Longitude">
          <Input />
        </Form.Item> */}
      </Form>
    </Modal>
  );
};

export default EditOutletModal;
