import { useState } from "react";
import "./NotificationsTab.css"; // Custom CSS for dropdown styling

const NotificationsTab = ({ notifications, loading }) => {
  const [open, setOpen] = useState(false); // State to control dropdown visibility

  return (
    <div className="notifications-container">
      <button className="bell-button" onClick={() => setOpen(!open)}>
        {loading ? "Loading..." : "🔔"}
      </button>

      {open && (
        <div className="dropdown">
          {notifications.length > 0 ? (
            notifications.map((notification) => (
              <div className="dropdown-item" key={notification.id}>
                <strong>{notification.title?.english || "No Title"}</strong>:{" "}
                {notification.description?.english || "No Description"}
              </div>
            ))
          ) : (
            <div className="dropdown-item">No notifications available</div>
          )}
        </div>
      )}
    </div>
  );
};

export default NotificationsTab;
