import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Table } from "antd";
import axios from "axios";
import "../../../assets/scss/General.scss";

const ViewLoyaltyUser = () => {
  const { id } = useParams(); // Get user ID from the route
  const [userInfo, setUserInfo] = useState(null);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [transLoading, setTransLoading] = useState(true);

  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    // Fetch user information
    const fetchUserInfo = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${import.meta.env.VITE_APP_BASE_API_URL}/loyaltyPoints/find-by-id/${id}`,
          {
            headers: {
              Authorization: `Bearer ${user.data.accessToken}`,
            },
          }
        );
        console.log("User Info Response:", response.data); // Log the response to check structure
        setUserInfo(response.data.userLoyaltyPoints); // Correct mapping to userLoyaltyPoints
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user information:", error);
        setLoading(false);
      }
    };

    fetchUserInfo();
  }, [id, user.data.accessToken]);

  // Columns for the transaction history table
  const columns = [
    { title: "Transaction ID", dataIndex: "id", key: "id" },
    { title: "Amount", dataIndex: "amount", key: "amount" },
    { title: "Date", dataIndex: "date", key: "date" },
    // Add more columns based on the structure of transaction data
  ];

  return (
    <div>
      {loading ? (
        <p>Loading user info...</p>
      ) : (
        <div className="user-info">
          <h2>User Information</h2>
          <p>
            <b>ID: </b>
            {userInfo?.id || "N/A"}
          </p>
          <p>
            <b>Email: </b>
            {userInfo?.userDetails?.email || "N/A"}
          </p>
          <p>
            <b>Total Points: </b>
            {userInfo?.totalPoints || "N/A"}
          </p>
          <p>
            <b>Expiry Date: </b>
            {userInfo?.expiryDate || "N/A"}
          </p>
        </div>
      )}

      <h2>Transaction History</h2>
      <Table
        dataSource={transactionHistory || []}
        columns={columns}
        rowKey="id"
        loading={transLoading}
      />
    </div>
  );
};

export default ViewLoyaltyUser;
