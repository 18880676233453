import "../assets/scss/DashboardHeader.scss";
import axios from "axios";
import { useEffect, useState } from "react";
import { Avatar, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setHotel, setHotelLoading } from "../store/slices/hotelSlice";
import NotificationsTab from "./NotificationsTab";
import { UserOutlined } from "@ant-design/icons";
import EditProfileModal from "./EditProfileModal";

const DashboardHeader = ({ menuWidth }) => {
  const [hotelData, setHotelData] = useState([]);
  const [editProfileModal, setEditProfileModal] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false); // For notifications loading state
  const user = JSON.parse(localStorage.getItem("user"));
  const hotelLoading = useSelector((state) => state.hotelReducer.loadingHotel);
  const dispatch = useDispatch();
  const superRoles = [1, 2, 12];

  const fetchUserNotifications = () => {
    setLoading(true);
    axios
      .get(
        `${import.meta.env.VITE_APP_BASE_API_URL}/notifications/get-notifications`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        setNotifications(res.data.notifications || []);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleChange = (value) => {
    dispatch(setHotelLoading(true));
    const selectedID = hotelData.find((hotel) => hotel.id === value);
    axios
      .get(
        `${import.meta.env.VITE_APP_BASE_API_URL}/services/admin-services?hotel=${value}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      )
      .then((res) => {
        dispatch(
          setHotel({
            hotelName: selectedID.name,
            hotelID: value,
            services: res.data.services,
          })
        );
        dispatch(setHotelLoading(false));
      });
  };

  const fetchAllHotelsAndUserHotel = () => {
    axios
      .get(`${import.meta.env.VITE_APP_BASE_API_URL}/hotels/get-hotels`, {
        headers: {
          Authorization: "Bearer " + user?.data?.accessToken,
        },
      })
      .then(function (response) {
        setHotelData(response.data.hotels);
        axios
          .get(
            `${import.meta.env.VITE_APP_BASE_API_URL}/services/admin-services?hotel=${user.data.userProfile.userDetails.hotelDetails.id}`,
            {
              headers: {
                Authorization: `Bearer ${user?.data?.accessToken}`,
              },
            }
          )
          .then((res) => {
            dispatch(
              setHotel({
                hotelName:
                  user?.data?.userProfile?.userDetails?.hotelDetails?.name
                    .english,
                hotelID: user?.data?.userProfile?.userDetails?.hotelDetails?.id,
                services: res.data.services,
              })
            );
            dispatch(setHotelLoading(false));
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const fetchSingleHotelServices = () => {
    axios
      .get(
        `${import.meta.env.VITE_APP_BASE_API_URL}/services/admin-services?hotel=${user.data.userProfile.userDetails.hotelDetails.id}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      )
      .then((res) => {
        dispatch(
          setHotel({
            hotelName:
              user?.data?.userProfile?.userDetails?.hotelDetails?.name.english,
            hotelID: user?.data?.userProfile?.userDetails?.hotelDetails?.id,
            services: res.data.services,
          })
        );
        dispatch(setHotelLoading(false));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const fetchUserHotel = () => {
    if (superRoles.includes(user.roles.id)) {
      fetchAllHotelsAndUserHotel();
    } else {
      fetchSingleHotelServices();
    }
  };

  useEffect(() => {
    fetchUserNotifications();
    fetchUserHotel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.data?.accessToken]);

  return (
    <header
      className="dashboard-header"
      style={{ padding: "24px 24px 24px", marginLeft: menuWidth }}
    >
      <div
        style={{ display: "flex", gap: 10, alignItems: "center" }}
        className="select-hotel"
      >
        <p>Hotel:</p>
        {user?.roles?.id !== 1 &&
          user?.roles?.id !== 2 &&
          user?.roles?.id !== 12 ? (
          <p>
            {user?.data?.userProfile?.userDetails?.hotelDetails?.name?.english}
          </p>
        ) : hotelData.length > 0 ? (
          <Select
            style={{ width: 250 }}
            defaultValue={user.data.userProfile.userDetails.hotelDetails.id}
            disabled={hotelLoading}
            onChange={handleChange}
          >
            {hotelData.map((hotel) => (
              <Select.Option key={hotel.id} value={hotel.id}>
                {hotel?.name?.english}
              </Select.Option>
            ))}
          </Select>
        ) : (
          "Loading Hotels"
        )}
      </div>
        {console.log("header",user.data.userProfile)}
      <div className="right">
        <div className="profile">
          <Avatar
            onClick={() => setEditProfileModal(true)}
            icon={
              user.data.userProfile.profilePicture ? (
                <img
                  src={user.data.userProfile.profilePicture}
                  alt="User Picture"
                />
              ) : (
                <UserOutlined />
              )
            }
          />
          <EditProfileModal
            setEditProfileModal={setEditProfileModal}
            editProfileModal={editProfileModal}
          />
        </div>
        <div className="notifications">
          <NotificationsTab notifications={notifications} loading={loading} />
        </div>
      </div>
    </header>
  );
};

export default DashboardHeader;
