import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from "recharts";
import "../../assets/scss/Charts.scss";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import dayjs from "dayjs";
import { DatePicker } from "antd";

const RoomServiceSoloChart = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const selectedHotel = useSelector((state) => state.hotelReducer.hotel);
  const [chartData, setChartData] = useState([]);
  const [dateRange, setDateRange] = useState([
    dayjs().format("YYYY-MM-DD"),
    dayjs().format("YYYY-MM-DD"),
  ]);

  const { RangePicker } = DatePicker;
  const fetchChart = () => {
    axios
      .get(
        `${
          import.meta.env.VITE_APP_BASE_API_URL
        }/statistics/roomService-orders?hotel=${
          selectedHotel.hotelID ? selectedHotel.hotelID : 100378095
        }&startDate=${dateRange[0]}&endDate=${dateRange[1]}`,
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      )
      .then((res) => {
        // console.log(res);
        let temp = [
          {
            name: "In Progress",
            Orders: res.data.inProgressOrders,
          },
          { name: "Completed", Orders: res.data.doneOrders },
          { name: "Cancelled", Orders: res.data.cancelledOrders },
          { name: "Pending", Orders: res.data.pendingOrders },
        ];
        setChartData(temp);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchChart();
  }, [selectedHotel.hotelID, dateRange]);

  return (
    <div className="roomServiceSoloChart single-chart">
      <h2>Room Service</h2>
      <RangePicker
        allowClear={false}
        onChange={(_, dateString) => setDateRange(dateString)}
        value={[dayjs(dateRange[0]), dayjs(dateRange[1])]}
      />
      <BarChart width={400} height={300} data={chartData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis
          label={{ value: "Room Service", angle: -90, position: "insideLeft" }}
        />
        <Tooltip />
        <Bar dataKey="Orders" fill="#cb9e6b" />
      </BarChart>
    </div>
  );
};

export default RoomServiceSoloChart;
