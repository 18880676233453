import { Form, Input, InputNumber, Modal } from "antd";
import axios from "axios";
import ReactQuill from "react-quill";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";

const EditOutletServiceItem = ({
  setEditItemModal,
  editItemModal,
  item,
  refetch,
}) => {
  const [editForm] = Form.useForm();
  const [desc, setDesc] = useState("");
  const [descAR, setDescAR] = useState("");
  const [descRU, setDescRU] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    setDesc(item?.description?.english );
    setDescAR(item?.description?.arabic );
    setDescRU(item?.description?.russian );
  }, [item]);

  const onFinish = async (values) => {
    console.log('data', item)
    const payload = {
      name: {
        english: values.itemName || item.name?.english,
        arabic: values.itemNameAR || item.name?.arabic,
        russian: values.itemNameRU || item.name?.russian,
      },
      description: {
        english: desc,  
        arabic: descAR,  
        russian: descRU, 
      },
      price: values.itemPrice || item.price,
    };

    await axios
      .patch(
        `${import.meta.env.VITE_APP_BASE_API_URL}/items?id=${item.id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      )
      .then((response) => {
        
        refetch();
        setEditItemModal(false);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  };

  const handleCancel = () => {
    editForm.resetFields();
    setEditItemModal(false);
  };

  return (
    <Modal
      okText={"Edit Item"}
      onOk={() => {
        editForm
          .validateFields()
          .then((values) => {
            onFinish(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      onCancel={handleCancel}
      open={editItemModal}
      centered
    >
      <Form
        form={editForm}
        initialValues={{
          price: item?.price,
          itemName: item?.name?.english,
          itemNameRU: item?.name?.russian,
          itemNameAR: item?.name?.arabic,
        }}
        layout="vertical"
        name="edit-service-item-modal"
      >
        <Form.Item
          name="itemName"
          label="Item Name"
          rules={[
            {
              required: true,
              message: "Please input the item name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="itemNameRU"
          label="Item Name in Russian"
          rules={[
            {
              required: true,
              message: "Please input the item name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="itemNameAR"
          label="Item Name in Arabic"
          rules={[
            {
              required: true,
              message: "Please input the item name!",
            },
          ]}
        >
          <Input dir="rtl" />
        </Form.Item>

        {/* English Description */}
        <Form.Item
          label="Item Description"
          rules={[
            {
              required: true,
              message: "Please input the item description!",
            },
          ]}
        >
          <ReactQuill theme="snow" value={desc} onChange={setDesc} key={`desc-${editItemModal}`} />
        </Form.Item>

        {/* Russian Description */}
        <Form.Item
          label="Item Description in Russian"
          rules={[
            {
              required: true,
              message: "Please input the item description!",
            },
          ]}
        >
          <ReactQuill theme="snow" value={descRU} onChange={setDescRU} key={`desc-${editItemModal}`} />
        </Form.Item>

        {/* Arabic Description */}
        <Form.Item
          label="Item Description in Arabic"
          rules={[
            {
              required: true,
              message: "Please input the item description!",
            },
          ]}
        >
          <ReactQuill theme="snow" value={descAR} onChange={setDescAR} key={`desc-${editItemModal}`} />
        </Form.Item>

        <Form.Item
          name="itemPrice"
          label="Item Price"
          initialValue={item.price}
          rules={[
            {
              type: "number",
              required: true,
              message: "Please input the price!",
            },
          ]}
        >
          <InputNumber style={{ width: "100%" }} controls={false} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditOutletServiceItem;
