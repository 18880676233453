import { Button, Popconfirm, Table, Typography } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AddNotificationModal from "./AddNotificationModal";
import dayjs from "dayjs";
import DOMPurify from 'dompurify';

// render description html
const renderHTML = (htmlString) => {
  const cleanHTML = DOMPurify.sanitize(htmlString);
  return (
    <div dangerouslySetInnerHTML={{ __html: cleanHTML }} />
  );
};
const PushNotificationsTab = () => {
  const auth = JSON.parse(localStorage.getItem("user"));
  const [data, setData] = useState();
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const selectedHotel = useSelector((state) => state.hotelReducer.hotel);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const columns = [
    {
      title: "Title",
      dataIndex: ["title", "english"],
    },
    {
      title: "Description",
      dataIndex: ["description", "english"],
      render: (htmlContent) => renderHTML(htmlContent),
    },
    {
      title: "Targets",
      dataIndex: "target",
    },
    {
      title: "Type",
      dataIndex: "type",
    },

    {
      title: "Repeat Interval",
      dataIndex: "repeatInterval",
    },
    {
      title: "Send Date",
      dataIndex: "sendDate",
      render: (date) => dayjs(date).format("YYYY-MM-DD hh:mm A"),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (record, rowIndex) => (
        <div className="row-actions">
          {rowIndex.active ? (
            <Popconfirm
              title="Deactivate Notification"
              description="Are you sure to deactivate this notification?"
              onConfirm={() => handleActiveClick(record, rowIndex)}
              okText="Yes"
              cancelText="No"
            >
              <Button loading={buttonLoading} danger type="primary">
                Deactivate
              </Button>
            </Popconfirm>
          ) : (
            <Popconfirm
              title="Activate Notification"
              description="Are you sure you want to activate this notification?"
              onConfirm={() => handleActiveClick(record, rowIndex)}
              okText="Yes"
              cancelText="No"
            >
              <Button loading={buttonLoading} type="primary">
                Activate
              </Button>
            </Popconfirm>
          )}
        </div>
      ),
    },
  ];

  const fetchData = () => {
    setLoading(true);
    axios
      .get(
        `${
          import.meta.env.VITE_APP_BASE_API_URL
        }/notifications/get-notifications?limit=${10}&page=${
          tableParams.pagination.current
        }`,
        {
          headers: {
            Authorization: `Bearer ${auth.data.accessToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        setData(res.data.notifications);
        setLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: res.data.totalCount,
          },
        });
      });
  };
  const handleAddNotification = () => {
    setAddModalOpen(true);
  };

  const handleActiveClick = (record, rowIndex) => {
    console.log(rowIndex);
    setButtonLoading(true);
    axios
      .patch(
        `${import.meta.env.VITE_APP_BASE_API_URL}/notifications?id=${
          rowIndex.id
        }`,
        {
          active: !rowIndex.active,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.data.accessToken}`,
          },
        }
      )
      .then(() => {
        fetchData();
      })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [addModalOpen]);
  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };
  return (
    <div>
      <div className="table-header">
        <Typography.Title level={2}>
          {/* {selectedHotel.hotelName} Offers List */}
        </Typography.Title>
        <Button
          type="primary"
          style={{ marginBottom: 24 }}
          onClick={handleAddNotification}
        >
          Create Notification
        </Button>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={tableParams.pagination}
        loading={loading}
        onChange={handleTableChange}
      />

      <AddNotificationModal
        hotel={selectedHotel}
        setAddModalOpen={setAddModalOpen}
        addModalOpen={addModalOpen}
      />
    </div>
  );
};

export default PushNotificationsTab;
