import { Rate, Table, Typography } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

const GuestList = () => {
  const auth = JSON.parse(localStorage.getItem("user"));
  const selectedHotel = useSelector((state) => state.hotelReducer.hotel);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    filters: {},
    sorter: { field: 'createdAt', order: 'descend' },
  });

  const columns = [
    {
      title: "Comment",
      dataIndex: "comment",
    },
    {
      title: "Stars",
      dataIndex: "starRating",
      render: (rate) => <Rate disabled defaultValue={rate} />,
    },
    {
      title: "Hotel",
      dataIndex: ["hotelDetails", "name", "english"],
      filters: [
        { text: "Golf", value: "Golf" },
        { text: "Dubai Marina", value: "Stella Di Mare Dubai Marina" },
      ],
      onFilter: (value, record) =>
        record.hotelDetails.name.english.indexOf(value) === 0,
    },
    {
      title: "User",
      dataIndex: "userDetails",
      render: (user) => (user === null ? "Anonymous" : user),
    },
  ];

  const fetchData = () => {
    setLoading(true);

    const { pagination, sorter } = tableParams;
    const hotelId = selectedHotel?.hotelID;

    axios
      .get(
        `${import.meta.env.VITE_APP_BASE_API_URL
        }/reviews/get-reviews?sortBy=${sorter.field}&sortOrder=${sorter.order === "ascend" ? "ASC" : "DESC"
        }&hotelID=${hotelId}&page=${pagination.current}&limit=${pagination.pageSize
        }`,
        {
          headers: {
            Authorization: `Bearer ${auth.data.accessToken}`,
          },
        }
      )
      .then((res) => {
        setData(res.data.reviews);
        setTableParams((prev) => ({
          ...prev,
          pagination: {
            ...pagination,
            total: res.data.totalCount, // Ensure total count is returned from API
          },
        }));
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching reviews:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [selectedHotel, tableParams.pagination.current, tableParams.sorter]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  return (
    <div>
      <div className="table-header">
        <Typography.Title level={2}>Reviews</Typography.Title>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={tableParams.pagination}
        loading={loading}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default GuestList;
