import { Form, Input, Modal } from "antd";
import axios from "axios";
import { useState, useEffect } from "react";
import { assign } from "lodash";
import Swal from "sweetalert2";
import UploadProfilePicture from "./UploadProfilePicture";

const EditProfileModal = ({ setEditProfileModal, editProfileModal }) => {
  const [form] = Form.useForm();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [originalValues, setOriginalValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [image, setimage] = useState("");

  useEffect(() => {
    
    if (user?.data?.userProfile) {
      const userProfile = user.data.userProfile;
      setimage(userProfile.profilePicture ); // Set to empty string if undefined
      console.log('hi',userProfile.profilePicture)
      const initialFormValues = {
        firstName: userProfile.firstName,
        lastName: userProfile.lastName,
        mobileNumber: userProfile.mobileNumber,
        email: userProfile.userDetails.email,
        nationality: userProfile.nationality,
        gender: userProfile.gender,
        image: image, // Ensure it matches expected field
      };

      form.setFieldsValue(initialFormValues);
      setOriginalValues(initialFormValues);
    }
  }, [user, form]);


  const onFinish = async () => {
    const allFields = form.getFieldsValue();
    const values = {};
  
    Object.keys(allFields).forEach((key) => {
      if (form.isFieldTouched(key)) {
        values[key] = allFields[key];
      }
    });
  
    // Ensure the image URL is properly set
    // If the image URL is not provided, use the existing image URL from the user profile
    if (!values.image) {
      values.image = image; // Use existing URL or set to empty
    }
  
    console.log("Values being sent in patch:", values); // Debug log
  
    setLoading(true);
    try {
      const res = await axios.patch(
        `${import.meta.env.VITE_APP_BASE_API_URL}/userProfiles?id=${user?.data?.userProfile?.id}`,
        values,
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      );
  
      // Update user state and localStorage with new data
      assign(user.data.userProfile, res.data.user);
      localStorage.setItem("user", JSON.stringify(user));
      setUser(JSON.parse(localStorage.getItem("user")));
      setEditProfileModal(false);
    } catch (err) {
      console.error(err);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: err.response?.data?.message || "An error occurred.",
      });
    } finally {
      setLoading(false);
    }
  };
  
  


  return (
    <Modal
      okText="Save"
      onOk={() => {
        form
          .validateFields()
          .then(() => onFinish())
          .catch((info) => console.log("Validate Failed:", info));
      }}
      okButtonProps={{ disabled: loading }}
      onCancel={() => {
        form.setFieldsValue(originalValues);
        setEditProfileModal(false);
      }}
      open={editProfileModal}
      confirmLoading={loading}
    >
      <Form form={form} name="edit-user" layout="vertical">
        <UploadProfilePicture
          value={image}
          onChange={(url) => {
            setimage(url); // Update the local state with the new image URL
            form.setFieldsValue({ profilePicture: url }); // Also update the form field
          }}
        />


        <Form.Item
          name="firstName"
          label="First Name"
          rules={[{ required: true, message: "Please input the First Name!", whitespace: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="lastName"
          label="Last Name"
          rules={[{ required: true, message: "Please input the Last Name!", whitespace: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="mobileNumber"
          label="Phone Number"
          rules={[{ required: true, message: "Please input the Phone Number!", len: 11 }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, type: "email", message: "Please input a valid email!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="nationality"
          label="Nationality"
          rules={[{ required: true, message: "Please input your Nationality!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="gender"
          label="Gender"
          rules={[{ required: true, message: "Please input your Gender!" }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditProfileModal;
