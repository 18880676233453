import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import "../../assets/scss/Charts.scss";

const LoyalityTiersChart = () => {
  const data = [
    {
      name: "Bronze",
      Total: 400,
      Earned: 240,
      Redeemed: 140,
    },
    {
      name: "Silver",
      Total: 300,
      Earned: 138,
      Redeemed: 38,
    },
    {
      name: "Gold",
      Total: 200,
      Earned: 50,
      Redeemed: 90,
    },
    {
      name: "Platinium",
      Total: 27,
      Earned: 19,
      Redeemed: 39,
    },
  ];
  return (
    <div className="LoyalityTiersChart single-chart">
      <h2>Loyalty Tiers</h2>
      <BarChart width={500} height={300} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="Total" fill="#cb9e6b" />
        <Bar dataKey="Earned" fill="#113b5f" />
        <Bar dataKey="Redeemed" fill="#246194" />
      </BarChart>
    </div>
  );
};

export default LoyalityTiersChart;
