import { Form, Input, Modal, Skeleton } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";

const ViewOrderModal = ({
  orderID,
  orderDetailsModal,
  setOrderDetailsModal,
  refetch,
}) => {
  const [data, setData] = useState({});
  const user = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoading] = useState({});
  const [form] = Form.useForm();
  const fetchOrder = () => {
    setLoading(true);
    axios
      .get(`${import.meta.env.VITE_APP_BASE_API_URL}/orders?id=${orderID}`, {
        headers: {
          Authorization: `Bearer ${user?.data?.accessToken}`,
        },
      })
      .then((res) => {
        setData(res.data.order);
        setLoading(false);
      });
  };

  const onFinish = async (values) => {
    console.log("Success:", values);
    setOrderDetailsModal(false);
    await axios
      .patch(
        `${import.meta.env.VITE_APP_BASE_API_URL}/orders?id=${orderID}`,
        {
          staffNotes: values.feedback,
        },
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      )
      .then(function (response) {
        form.resetFields();
        setOrderDetailsModal(false);
        console.log(response);
        // refetch();
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  };

  useEffect(() => {
    fetchOrder();
  }, [orderID]);

  console.log("%c Oh my heavens! ", "background: #222; color: #bada55", data);
  // console.log(data.orderType.english);

  return (
    <div>
      <Modal
        destroyOnClose
        // cancelText={"Close"}
        okText={"Update"}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              onFinish(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
        onCancel={() => setOrderDetailsModal(false)}
        // okButtonProps={{ style: { display: "none" } }}
        open={orderDetailsModal}
      >
        {loading ? (
          <Skeleton active />
        ) : (
          <div>
            <h2 className="grouped-info-title">Assignee details</h2>
            <div className="grouped-info">
              <p>
                <b>Name: </b>
                {data?.assignedUserDetails?.userProfile?.firstName}
                {data?.assignedUserDetails?.userProfile?.lastName}
              </p>
              <p>
                <b>Phone Number: </b>
                {data?.assignedUserDetails?.userProfile?.mobileNumber}
              </p>
              {data.area === null ? (
                <p>
                  <b>Room No: </b>
                  {data?.roomDetails?.roomNo}
                </p>
              ) : (
                <p>
                  <b>Area: </b>
                  {data?.areaDetails?.name}
                </p>
              )}
            </div>

            <h2 className="grouped-info-title">Order details</h2>
            <div className="grouped-info">
              <p>
                <b>Created date:</b>{" "}
                {dayjs(data?.createdAt).format("DD/MM/YYYY - HH:mm")}
              </p>
              <p>
                <b>Status: </b>
                {data?.status}
              </p>

              <p>
                <b>Service: </b>
                {data.orderItems[0]?.itemDetails?.serviceCategoryDetails?.serviceDetails?.name}
              </p>

              {data?.orderItems?.map((item) => (
                <p key={item.id}>
                  <b>Quantity: </b>
                  {item?.quantity}x {item?.itemDetails?.name}
                  {item?.itemDetails?.price !== 0 &&
                    ` - $${item?.itemDetails?.price}`}
                  {item?.comment !== null && `Comment: ${item?.comment}`}
                </p>
              ))}
              <p>
                <b>Assigned to: </b>
                {data.assignedUserDetails?.email || 'N/A'}
              </p>
            </div>

            <Form form={form} layout="vertical" name="update-staff-feedback">
              <Form.Item
                name="feedback"
                initialValue={data.staffNotes}
                label="Staff Feedback"
              >
                <Input.TextArea />
              </Form.Item>
            </Form>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ViewOrderModal;
