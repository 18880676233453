import { Form, Input, Modal, TimePicker } from "antd";
import axios from "axios";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import UploadItemIcon from "../../components/UploadIcon";
import { useState } from "react";

const AddOutletModal = ({
  setAddItemModal,
  refetch,
  addItemModal,
  clickedCategory,
}) => {
  const selectedHotel = useSelector((state) => state.hotelReducer.hotel);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const onFinish = async (values) => {
    setLoading(true);

    console.log("Success:", values);
    await axios
      .post(
        `${import.meta.env.VITE_APP_BASE_API_URL}/services`,
        {
          name: {
            english: values.itemName,
            russian: values.itemNameRU,
            arabic: values.itemNameAR,
          },
          active: true,
          description: {
            english: "desc",
            russian: "desc",
            arabic: "desc",
          },
          hotel: selectedHotel.hotelID ? selectedHotel.hotelID : 100378095,
        },
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        axios
          .post(
            `${import.meta.env.VITE_APP_BASE_API_URL}/items`,
            {
              images: [values.icon],
              name: {
                english: values.itemName,
                russian: values.itemNameRU,
                arabic: values.itemNameAR,
              },
              description: { english: "desc", russian: "desc", arabic: "desc" },
              price: 0,
              service: res.data.createdService.id,
              serviceCategory: clickedCategory.id,
              extraFields: {
                phone: values.phone,
                description: {
                  english: values.description,
                  arabic: values.descriptionAR,
                  russian: values.descriptionRU,
                },
                outletType: {
                  english: values.outletType,
                  russian: values.outletType,
                  arabic: values.outletType,
                },
                openingHours: {
                  from: values.time[0].format("HH:mm"),
                  to: values.time[1].format("HH:mm"),
                  description: {
                    english: values.openingHoursDesc,
                    arabic: values.openingHoursDescAR,
                    russian: values.openingHoursDescRU,
                  },
                },
                location: {
                  // lat: values.locationLat,
                  // lng: values.locationLng,
                  name: {
                    english: values.locationName,
                    arabic: values.locationNameAR,
                    russian: values.locationNameRU,
                  },
                },
              },
            },
            {
              headers: {
                Authorization: `Bearer ${user.data.accessToken}`,
              },
            }
          )
          .then(function (response) {
            form.resetFields();
            console.log(response);
            refetch();
            setAddItemModal(false);
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.response.data.message,
            });
          });
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    setAddItemModal(false);
  };

  return (
    <Modal
      confirmLoading={loading}
      okText={"Create item"}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            console.log("values", values);
            onFinish(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      onCancel={handleCancel}
      open={addItemModal}
      centered
    >
      <Form form={form} layout="vertical" name="addOutletModal">
        <Form.Item
          name="icon"
          rules={[
            {
              required: true,
              message: "Please upload the photo!",
            },
          ]}
        >
          <UploadItemIcon />
        </Form.Item>

        <Form.Item
          name="itemName"
          label="Item Name"
          rules={[
            {
              required: true,
              message: "Please input the item name!",
            },
            {
              whitespace: true,
              message: "Please input valid item name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="itemNameRU"
          label="Item Name in Russian"
          rules={[
            {
              required: true,
              message: "Please input the item name!",
            },
            {
              whitespace: true,
              message: "Please input valid item name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="itemNameAR"
          label="Item Name in Arabic"
          rules={[
            {
              required: true,
              message: "Please input the item name!",
            },
            {
              whitespace: true,
              message: "Please input valid item name!",
            },
          ]}
        >
          <Input dir="rtl" />
        </Form.Item>
        <Form.Item
          name="outletType"
          label="Outlet Type"
          rules={[
            {
              whitespace: true,
              message: "Please input valid Outlet Type!",
            },
            {
              required: true,
              message: "Please input Outlet Type!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="outletTypeRU"
          label="Outlet Type in Russian"
          rules={[
            {
              required: true,
              message: "Please input Outlet Type!",
            },
            {
              whitespace: true,
              message: "Please input valid Outlet Type!!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="outletTypeAR"
          label="Outlet Type in Arabic"
          rules={[
            {
              required: true,
              message: "Please input Outlet Type!",
            },
            {
              whitespace: true,
              message: "Please input valid Outlet Type!",
            },
          ]}
        >
          <Input dir="rtl" />
        </Form.Item>
        <Form.Item>{/* <UploadMultipleImages /> */}</Form.Item>
        <Form.Item
          name="phone"
          label="Restaurant Phone"
          type="number"
          rules={[
            {
              required: true,
              message: "Please input the restaurant phone number!",
              len: 11,
            },
            {
              whitespace: true,
              message: "Please input a valid phone number!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              whitespace: true,
              message: "Please input valid description!",
            },
            {
              required: true,
              message: "Please input description!",
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label="Description in Russian"
          name="descriptionRU"
          rules={[
            {
              whitespace: true,
              message: "Please input valid description!",
            },
            {
              required: true,
              message: "Please input description!",
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label="Description in Arabic"
          name="descriptionAR"
          rules={[
            {
              whitespace: true,
              message: "Please input valid description!",
            },
            {
              required: true,
              message: "Please input description!",
            },
          ]}
        >
          <Input.TextArea dir="rtl" />
        </Form.Item>
        <Form.Item
          name="time"
          label="Opening Hours (FROM : TO)"
          rules={[
            {
              required: true,
              message: "Please input the item name!",
            },
          ]}
        >
          <TimePicker.RangePicker
            allowClear={false}
            format={"HH:mm"}
            minuteStep={15}
          />
        </Form.Item>
        <Form.Item
          label="Opening Hours Description"
          name="openingHoursDesc"
          rules={[
            {
              required: true,
              message: "Please input Opening Hours Description!",
            },
            {
              whitespace: true,
              message: "Please input valid info!",
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label="Opening Hours Description in Russian"
          name="openingHoursDescRU"
          rules={[
            {
              required: true,
              message: "Please input Opening Hours Description!",
            },
            {
              whitespace: true,
              message: "Please input valid info!",
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label="Opening Hours Description in Arabic"
          name="openingHoursDescAR"
          rules={[
            {
              required: true,
              message: "Please input Opening Hours Description!",
            },
            {
              whitespace: true,
              message: "Please input valid info!",
            },
          ]}
        >
          <Input.TextArea dir="rtl" />
        </Form.Item>
        <Form.Item
          name="locationName"
          label="Location Name"
          rules={[
            {
              required: true,
              message: "Please input Location Name!",
            },
            {
              whitespace: true,
              message: "Please input valid Location Name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="locationNameRU"
          label="Location Name in Russian"
          rules={[
            {
              required: true,
              message: "Please input Location Name!",
            },
            {
              whitespace: true,
              message: "Please input valid Location Name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="locationNameAR"
          label="Location Name in Arabic"
          rules={[
            {
              required: true,
              message: "Please input Location Name!",
            },
            {
              whitespace: true,
              message: "Please input valid Location Name!",
            },
          ]}
        >
          <Input dir="rtl" />
        </Form.Item>
        {/* <Form.Item
          name="locationLat"
          label="Latitude"
          rules={[
            {
              required: true,
              message: "Please input Latitude!",
            },
            {
              whitespace: true,
              message: "Please input valid Latitude!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="locationLng"
          label="Longitude"
          rules={[
            {
              required: true,
              message: "Please input Longitude!",
            },
            {
              whitespace: true,
              message: "Please input valid Longitude!",
            },
          ]}
        >
          <Input />
        </Form.Item> */}
      </Form>
    </Modal>
  );
};

export default AddOutletModal;
