import { Button, Form, Input, Modal } from "antd";
import Swal from "sweetalert2";
import axios from "axios";
import { useState, useEffect } from "react";
import UploadMultipleImagesHotel from "../../components/UploadMultipleImagesHotel";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css'; 

const EditHotelModal = ({ data, refetch }) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const [form] = Form.useForm();

  const [desc, setDesc] = useState("");
  const [descAR, setDescAR] = useState("");
  const [descRU, setDescRU] = useState("");

  useEffect(() => {
    if (modal) { 
      setDesc(data?.description?.english); 
      setDescAR(data?.description?.arabic); 
      setDescRU(data?.description?.russian); 

      form.setFieldsValue({
        name: data?.name?.english,
        nameRU: data?.name?.russian,
        nameAR: data?.name?.arabic,
      });
    }
  }, [data, modal, form]); 

  useEffect(() => {
    if (modal) { 
      form.setFieldsValue({
        description: desc,
        descriptionAR: descAR,
        descriptionRU: descRU,
      });
    }
  }, [desc, descAR, descRU, modal, form]);

  const onFinish = async (values) => {
    setLoading(true);
    await axios
      .patch(
        `${import.meta.env.VITE_APP_BASE_API_URL}/hotels?id=${data.id}`,
        {
          name: {
            english: values.name,
            arabic: values.nameAR,
            russian: values.nameRU,
          },
          description: {
            english: desc,
            russian: descRU,
            arabic: descAR,
          },
          images: values.images.map((object) => {
            if (object.file === undefined && object.status === undefined) {
              return {
                id: object.uid,
                link: object.url,
                name: object.name,
              };
            } else if (
              object.file === undefined &&
              object.status === "removed"
            ) {
              return {
                id: object.uid,
                link: object.url,
                name: object.name,
                status: "removed",
              };
            } else {
              return object;
            }
          }),
        },
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      )
      .then(() => {
        form.resetFields();
        refetch(); 
        setModal(false);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleModalCancel = () => {
    form.resetFields();
    setModal(false);
  };

  return (
    <>
      <Button onClick={() => setModal(true)}>View</Button>
      <Modal
        onCancel={handleModalCancel}
        open={modal}
        destroyOnClose
        okText={"Edit Hotel"}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              onFinish(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
        centered
        confirmLoading={loading}
      >
        <Form
          form={form}
          layout="vertical"
          name="editHotelModal"
        >
          <Form.Item label="Name" name="name">
            <Input />
          </Form.Item>
          <Form.Item label="Name in Russian" name="nameRU">
            <Input />
          </Form.Item>
          <Form.Item label="Name in Arabic" name="nameAR">
            <Input />
          </Form.Item>
          <Form.Item name="images">
            <UploadMultipleImagesHotel data={data.images} />
          </Form.Item>
          <Form.Item label="Description" name="description">
            <ReactQuill 
              theme="snow" 
              value={desc} 
              onChange={(value) => setDesc(value)} 
            />
          </Form.Item>
          <Form.Item label="Description in Russian" name="descriptionRU">
            <ReactQuill 
              theme="snow" 
              value={descRU} 
              onChange={(value) => setDescRU(value)} 
            />
          </Form.Item>
          <Form.Item label="Description in Arabic" name="descriptionAR">
            <ReactQuill 
              theme="snow" 
              value={descAR} 
              onChange={(value) => setDescAR(value)} 
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default EditHotelModal;
