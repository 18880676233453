import { Form, Input, Modal } from "antd";
import axios from "axios";
import ReactQuill from "react-quill";
import Swal from "sweetalert2";
import "react-quill/dist/quill.snow.css";
import UploadItemIcon from "../../components/UploadIcon";
import { useState } from "react";
const AddExcursionsItem = ({
  setAddItemModal,
  addItemModal,
  clickedCategory,
  refetch,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const onFinish = async (values) => {
    setLoading(true);
    console.log("Success:", values);
    await axios
      .post(
        `${import.meta.env.VITE_APP_BASE_API_URL}/items`,
        {
          images: [values.icon],
          name: {
            english: values.itemName,
            arabic: values.itemNameAR,
            russian: values.itemNameRU,
          },
          description: {
            english: values.description,
            arabic: values.descriptionAR,
            russian: values.descriptionRU,
          },
          price: 0,
          serviceCategory: clickedCategory.id,
          extraFields: {
            location: {
              lat: values.locationLat,
              lng: values.locationLng,
              name: values.locationName,
            },
            description: {
              english: values.description,
              arabic: values.descriptionAR,
              russian: values.descriptionRU,
            },
            information: {
              english: values.information,
              arabic: values.informationAR,
              russian: values.informationRU,
            },
            termsAndConditions: {
              english: values.termsAndConditions,
              arabic: values.termsAndConditionsAR,
              russian: values.termsAndConditionsRU,
            },
          },
        },
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      )
      .then(function (response) {
        form.resetFields();
        console.log(response);
        refetch();
        setAddItemModal(false);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    form.resetFields();

    setAddItemModal(false);
  };
  return (
    <Modal
      confirmLoading={loading}
      okText={"Create item"}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onFinish(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      onCancel={handleCancel}
      open={addItemModal}
      centered
    >
      
      <Form form={form} layout="vertical" name="addExcursionsItem">
        <Form.Item name="icon" label="Icon" rules={[{
              required: true,
              message: "Please input the item icon!",
        }]}>
          <UploadItemIcon />
        </Form.Item>

        <Form.Item
          name="itemName"
          label="Item Name"
          rules={[
            {
              required: true,
              message: "Please input the item name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="itemNameRU"
          label="Item Name in Russian"
          rules={[
            {
              required: true,
              message: "Please input the item name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="itemNameAR"
          label="Item Name in Arabic"
          rules={[
            {
              required: true,
              message: "Please input the item name!",
            },
          ]}
        >
          <Input dir="rtl" />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <ReactQuill theme="snow" />
        </Form.Item>
        <Form.Item name="descriptionRU" label="Description in Russian">
          <ReactQuill theme="snow" />
        </Form.Item>
        <Form.Item name="descriptionAR" label="Description in Arabic">
          <ReactQuill theme="snow" />
        </Form.Item>
        <Form.Item name="information" label="Information">
          <ReactQuill theme="snow" />
        </Form.Item>
        <Form.Item name="informationRU" label="Information in Russian">
          <ReactQuill theme="snow" />
        </Form.Item>
        <Form.Item name="informationAR" label="Information in Arabic">
          <ReactQuill theme="snow" />
        </Form.Item>
        <Form.Item name="termsAndConditions" label="Terms and Conditions">
          <ReactQuill theme="snow" />
        </Form.Item>
        <Form.Item
          name="termsAndConditionsRU"
          label="Terms and Conditions in Russian"
        >
          <ReactQuill theme="snow" />
        </Form.Item>
        <Form.Item
          name="termsAndConditionsAR"
          label="Terms and Conditions in Arabic"
        >
          <ReactQuill theme="snow" />
        </Form.Item>
        <Form.Item
          name="locationName"
          style={{ display: "inline-block", marginRight: 10, width: "30%" }}
          label="Location Name"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="locationLat"
          style={{ display: "inline-block", marginRight: 10, width: "30%" }}
          label="Latitude"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="locationLng"
          style={{ display: "inline-block", width: "30%" }}
          label="Longitude"
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddExcursionsItem;
